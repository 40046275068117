import { AfterViewInit, Component, Renderer2 } from '@angular/core';

import { JwtAuthService } from './shared/services/auth/jwt-auth.service';
import { AmplitudeService } from './shared/services/amplitude.service';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { PersonMessagesEntityService } from './state/entity-services/person-messages-entity.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  title = 'patient';

  constructor(
    private personMessagesEntityService: PersonMessagesEntityService,
    private renderer: Renderer2,
    private router: Router, private viewportScroller: ViewportScroller,
    private amplitudeService: AmplitudeService,
    private jwtAuthService: JwtAuthService) {

    if (jwtAuthService.isLoggedIn) {
      this.personMessagesEntityService.checkJobEvents().subscribe({
        next: data => {
          console.log(data);
        }
      });
    }
  }


  ngAfterViewInit() {
    this.amplitudeService.init(undefined);
    this.jwtAuthService.setUserSentry();
  }
}

